<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">ブランド事業者無効化</h2>
                <hr class="hp_flexspacer"/>
                <p>以下のブランド事業者を無効化します。よろしいですか？</p>
            </div>
            <error-list />
            <div class="ly_form_box">
                <div class="formrow">
                    <app-label
                        name="name"
                        label="ブランド事業者名"
                    />
                    <p ref="jest-user-name">{{data.name}}</p>
                </div>
                <div class="formrow">
                    <app-label
                        name="name"
                        label="事業者名"
                    />
                    <p ref="jest-user-name">{{data.organization.name}}</p>
                </div>
                <div class="formrow">
                    <app-label
                        name="name"
                        label="備考"
                    />
                    <p ref="jest-user-name">{{data.note}}</p>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/admin/brand_names')"
                    ref="jest-brand-name-cancel"
                    class="cancel"
                />
                <app-button
                    name="無効化"
                    @click="deleteBrandName"
                    ref="jest-brand-name-delete"
                    class="delete"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: 'AdminBrandNameDelete',
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            data:'admin/brandNames/brandName',
        }),
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            delete:'admin/brandNames/delete'
        }),
        moveTo(path){
            move(this.$router, path)
        },
        deleteBrandName(){
            this.clearError()
            return this.delete()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/brand_names')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 600px;
    max-width: 600px;
}
.ly_form_box{
    width: 100%;
}
.hp_flexspacer{
    max-width: 500px;
}
.ly_form_box .formrow{
    width: 100%;
    grid-template-columns: 9em 1fr;
    min-width: 500px;
}
</style>
<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">ブランド事業者{{typeName}}</h2>
                <hr class="hp_flexspacer"/>
            </div>
            <error-list />
            <div class="ly_form_box">
                <app-text
                    name="name"
                    v-model="name"
                    label="ブランド事業者名"
                    class="formrow"
                    ref="jest-brand-name-name"
                />
                <div class="input_container formrow">
                    <app-label
                        name="organization_id"
                        label="事業者名称"
                        class="rowlabel grid1"
                    />
                    <app-select
                        v-model="organization_id"
                        :label="'選択してください'"
                        ref="jest-brand-name-organization-id"
                        :vmodel-label="vModelValue"
                    >
                        <app-select-option
                            :value="null"
                            v-model="organization_id"

                        >
                            未選択
                        </app-select-option>
                        <app-select-option
                            v-for="item in brandOrganizations"
                            :key="'organizationsStatus' + item.id"
                            :value="item.id"
                            v-model="organization_id"
                        >
                            {{item.name}}
                        </app-select-option>
                    </app-select>
                </div>
                <app-text
                    name="note"
                    v-model="note"
                    label="備考"
                    class="formrow wd_22em"
                    ref="jest-brand-name-note"
                />
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/admin/brand_names')"
                    ref="jest-brand-name-cancel"
                    class="cancel"
                />
                <app-button
                    name="変更"
                    v-if="isEdit"
                    @click="edit"
                    ref="jest-brand-name-edit"
                />
                <app-button
                    name="新規登録"
                    v-if="isCreate"
                    @click="create"
                    ref="jest-brand-name-create"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move, mapComputedProperties } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: 'AdminBrandNameEdit',
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            data:'admin/brandNames/brandName',
            brandOrganizations:'admin/organization/brandOrganizations',
        }),
        ...mapComputedProperties([
            'name',
            'organization_id',
            'note'
        ]),
        typeName(){
            return {
                edit:'変更',
                create:'作成'
            }[this.$route.meta.type]
        },
        isEdit(){
            return this.$route.meta.type === 'edit'
        },
        isCreate(){
            return this.$route.meta.type === 'create'
        },
        vModelValue(){
            const item = this.brandOrganizations.filter((item) => item.id == this.organization_id)[0]
            return item ? item.name : null
        }
    },
    async mounted(){
        await this.getBrandOrganizations()
    },
    methods:{
        ...mapActions({
            update:'admin/brandNames/updateBrandName',
            getBrandOrganizations:'admin/organization/getBrandOrganizations',
            createBrandName:'admin/brandNames/create',
            editBrandName:'admin/brandNames/edit',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            move(this.$router, path)
        },
        create(){
            this.clearError()
            return this.createBrandName()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/brand_names')
                    } else {
                        this.updateError(response)
                    }
                })
        },
        edit(){
            this.clearError()
            return this.editBrandName()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/brand_names')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 600px;
    max-width: 600px;
}
.ly_form_box{
    width: 100%;
}
.ly_form_box .formrow{
    width: 100%;
    grid-template-columns: 9em 1fr;
}
.formrow.wd_22em >>> input[type="text"]{
    width: 22em;
}
.bl_contents_wrapper >>> .select_wrapper div.select_btn{
    min-width: 19em;
}
</style>
<template>
  <ul
    v-if="hasError"
    class="bl_errorList"
  >
      <li
        v-for="(item,index) in errors"
        :key="index"
      >
          {{ item }}
      </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapComputedProperties } from '@/util'

export default {
  name: 'ErrorList',
  computed: {
    ...mapGetters('error', {
      data:'errors'
    }),
    ...mapComputedProperties([
      'errors'
    ]),
    hasError() {
      return this.errors.length !== 0
    }
  }
}
</script>

<style scoped>
/* エラーの表示「警告」 */
ul.bl_errorList{
    display: block;
    width: fit-content;
    max-width: 850px;
    margin: 16px auto;
    padding: 0px 16px;
    color:  #BE4316;
    border-bottom: 2px solid  #BE4316;
    list-style-type: none;
}
li{
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
}
/* 黒文字の「通知」 */
ul.bl_errorList.notice {
    width: fit-content;
    min-width: 400px;
    color: #555;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #555;
    text-align: center;
    list-style-type: none;
}
</style>

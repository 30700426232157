// 使用時は、プログレスバーを終了したい箇所に finishProgress
// 進行中に表示したくない箇所に v-show="!isInProgress" を挟むこと
export default {
    computed: {
        isInProgress(){
            return this.$store.getters['progress/isInProgress']
        }
    },
    methods:{
        startProgress() {
            this.$store.dispatch('progress/startProgress')
        },
        finishProgress() {
            this.$store.dispatch('progress/finishProgress')
        }
    }
}
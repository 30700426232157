<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">ブランド事業者一覧</h2>
                 <app-button
                    name="新規作成"
                    @click="createBrandName()"
                    ref="jest-brand-name-create"
                    class="inline"
                />
            </div>
            <error-list class="notice" />
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table
                v-if="brandNames.length !== 0"
                key="resultTable"
                ref="jest-result-table"
                class="lists_table"
            >
                <thead>
                    <tr>
                        <th>ブランド事業者名</th>
                        <th>事業者名</th>
                        <th>ステータス</th>
                        <th>備考</th>
                        <th></th>
                    </tr>
                </thead>
                <tr
                    v-for="item in brandNames"
                    :key="'brandNames' + item.id"
                    :ref="'jest-brand-names' + item.id"
                >
                    <td>{{item.name}}</td>
                    <td>{{item.organization.name}}</td>
                    <td>{{enabled(item)}}</td>
                    <td class="uq_wd_20em">{{item.note}}</td>
                    <td>
                        <app-button
                            v-if="item.enabled"
                            name="変更"
                            @click="updateBrandName(item)"
                            :ref="'jest-brand-name-update' + item.id"
                            class="inline wd_80px outlined"
                        />
                        <app-button
                            v-if="item.enabled"
                            name="無効化"
                            class="delete inline wd_80px outlined"
                            @click="deleteBrandName(item)"
                            :ref="'jest-brand-name-delete' + item.id"
                        />
                    </td>
                </tr>
            </table>
            <list-pager
                v-if="brandNames.length !== 0"
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
            <p
                v-if="brandNames.length === 0"
                key="resultParagraph"
                ref="jest-result-paragraph"
            >
                ブランド事業者がありません
            </p>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move, mapComputedProperties, pageContentCount } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'
import ListPager from '@/components/molecules/ListPager'

export default {
    name: 'AdminBrandNameList',
    components:{
        ErrorList,
        ListPager,
    },
    computed:{
        ...mapGetters({
            brandNames:'admin/brandNames/brandNames',
            data:'admin/brandNames/pageStatus'
        }),
        ...mapComputedProperties([
            'total',
            'page',
        ]),
        /**
         * リストの件数の合計から算出されるリストのページ数を返す
         */
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        enabled(){
            return function(item) {
                return item.enabled ? '有効' : '無効'
            }
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        await this.getBrandNames()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getBrandNames:'admin/brandNames/getBrandNames',
            update:'admin/brandNames/update',
            clearError:'error/clear',
        }),
        /**
         * ブランド事業者リストのページの切り替え
         */
        async pageChange(page){
            const loader = this.$loading.show()
            await this.update({page})
            await this.getBrandNames()
            loader.hide()
        },
        /**
         * ブランド事業者リストのページの切り替え
         */
        async search(){
            const loader = this.$loading.show()
            await this.update({page:1})
            await this.getBrandNames()
            loader.hide()
        },
        moveTo(path){
            move(this.$router, path)
        },
        async createBrandName(){
            this.clearError()
            await this.update({brandName:{
                name:null,
                organization_id:null,
                note:null,
            }})
            this.moveTo('/admin/brand_names/create')
        },
        async updateBrandName(item){
            this.clearError()
            await this.update({brandName:item})
            this.moveTo('/admin/brand_names/' + item.id + '/edit')
        },
        async deleteBrandName(item){
            this.clearError()
            await this.update({brandName:item})
            this.moveTo('/admin/brand_names/' + item.id + '/delete')
        },
    }
}
</script>

<style scoped>
.lists_table tr td:last-child{
    width: 180px;
}
thead th{
    white-space: nowrap;
} 
.el_button.inline.wd_80px:not(:last-child){
    margin-right: 8px;
}
.uq_wd_20em{
    width: fit-content;
    max-width: 30em;
}
@media screen and (max-width:1280px) { 
    .lists_table tr td:last-child{
        width: 80px;
    }
    .el_button.inline.wd_80px:not(:last-child){
        margin-right: 0;
        margin-bottom: 8px;
    }
}
</style>
<template>
    <div class="pager">
        <div class="pagerContainer">
            <div 
                class="pagerContainer_child pagerContainer_child__leftBlank"
                v-if="pageCount == 0 || page < 2"
            />
            <app-button
                name="最初へ"
                @click="pageChange(1)"
                v-if="pageCount != 0 && page > 1"
                class="pagerContainer_child pagerContainer_start"
                ref="jest-list-pager-first"
            />
            <app-button
                name="前へ"
                @click="pageChange(page - 1)"
                v-if="pageCount != 0 && page > 1"
                class="pagerContainer_child pagerContainer_prev"
                ref="jest-list-pager-back"
            />
            <div class="pagerContainer_child pagerContainer_nowpage">
                <p>{{ page }}</p>
            </div>
            <app-button
                name="次へ"
                @click="pageChange(page + 1)"
                v-if="pageCount != 0 && page < pageCount"
                class="pagerContainer_child pagerContainer_next"
                ref="jest-list-pager-next"
            />
            <app-button
                name="最後へ"
                @click="pageChange(pageCount)"
                v-if="pageCount != 0 && page < pageCount"
                class="pagerContainer_child pagerContainer_end"
                ref="jest-list-pager-last"
            />
             <div 
                class="pagerContainer_child pagerContainer_child__rightBlank"
                v-if="pageCount == 0 || page >= pageCount"
            />
        </div>
    </div>
</template>

<script>
import Progress from '@/mixins/progress.js'

export default {
    name:'ListPager',
    props:{
        pageCount:{
            type:Number,
            required:true
        },
        page:{
            type:Number,
            required:true
        },
    },
    mixins:[Progress],
    computed:{
        /**
         * 各ページごとのページネータ表示判定
         * @param {Integer} num ページネータが持つページ番号
         */
        showPagenator(){
            return function (num) {
                return num != 1 && num != this.pageCount && ((num >= this.page - 1 && num <= this.page + 1) || this.pageCount <= 5)
            }
        },
        /**
         * 現在選択されているページの前に「…」を表示するか否かの判定
         */
        showOtherBefore(){
            return this.pageCount > 2 && !this.showPagenator(2)
        },
        /**
         * 現在選択されているページの後後に「…」を表示するか否かの判定
         */
        showOtherAfter(){
            return this.pageCount > 2 && !this.showPagenator(this.pageCount - 1)
        }
    },
    methods:{
        async pageChange(page){
            if(page >= 1 && this.pageCount >= page){
                this.startProgress()
                // 再取得を待つ
                await this.$emit('page-change', page)
                this.finishProgress()
            }
        },
    }
}
</script>

<style scoped>
.pager{
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.pager p:first-child{
  width: 10em;
  top: 0;
  position: absolute;
}
.pager p:first-child span{
  margin: 0 4px;
  font-size: 18px;
  line-height: 18px;
  font-weight: bolder;
}
.pager .pagerContainer{
  display: flex;
  width: 100%;
  margin: 0 auto;
}
.pager .pagerContainer .pagerContainer_child{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 7em;
  max-width: 7em;
}
.pager .pagerContainer .pagerContainer_nowpage{
  justify-content: center;
}
.pager .pagerContainer .pagerContainer_child p{
    margin: 0;
    font-size: 20px;
    text-align: center;
}
.pager .pagerContainer .pagerContainer_child:before,
.pager .pagerContainer .pagerContainer_child:after{
    color: #ccc;
    font-size: 18px;
}
.pager .pagerContainer .pagerContainer_child__leftBlank{
    width: 14em;
    max-width: 14em;
    margin-right: auto;
}
.pager .pagerContainer .pagerContainer_child__rightBlank{
    width: 14em;
    max-width: 14em;
    margin-left: auto;
}
.pager .pagerContainer .pagerContainer_start:before{
    content: "<<";
    display: inline-flex;
}
.pager .pagerContainer .pagerContainer_prev{
  width: 5em;
  max-width: 5em;
  margin-left: 32px;
  margin-right: auto;
}
.pager .pagerContainer .pagerContainer_prev:before{
    content: "<";
    display: inline-flex;
}
.pager .pagerContainer .pagerContainer_next{
  width: 5em;
  max-width: 5em;
  margin-right: 32px;
  margin-left: auto;
}
.pager .pagerContainer .pagerContainer_next:after{
  content: ">";
  display: inline-flex;
}
.pager .pagerContainer .pagerContainer_end:after{
  content: ">>";
  display: inline-flex;
}
.pager button{
  width: fit-content;
  min-width: 2em;
  height: 2em;
  margin: 0;
  padding: 4px 6px;
  color:#2c3e50;
  background-color: white;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}

.pager button:hover{
  border-bottom: 2px solid #4A6533;
  box-shadow: none;
}
.pager button.nowpage{
  min-width: 2em;
  height: 2em;
  color: white;
  background-color: #4A6533;
  font-weight: bolder;
}
</style>